.cart {
    display: flex;
    color: #0935ef;
    flex-direction: column;
}

.cart img {
    width: 5vw;
}
.cart__table_text{
    display: flex;
    color: #0935ef;
    font-size: 1.2vw;
    padding-left: 5vw;
}


.cart__table_row th {
    font-size: 1.5vw;
    height: 7vh;
    align-items: center;
}

.cart__table_row td {
    align-items: center;
    text-align: center;
}

.cart__table_product {
    width: 20vw;
    padding-left: 5vw;

}

.cart__table_product_key {
    width: 10vw;
    font-size: 1.2vw;
}

.cart__table_quantity{
    padding-left: 5vh;
}

.cart__table_product_price {
    width: 5vw;
    font-size: 1.2vw;
}

.cart__table_product_total {
    width: 5vw;
    font-size: 1.2vw;
}

.cart__table_quantity_btn {
    background-color: #f1e8e8;
    color: rgba(13, 51, 140, 0.51);
    border: 1px solid #007bff;
    border-radius: 4px;
    cursor: pointer;
    height: 3vh;
    width: 4vh;
    margin-top: 0vw;
    margin-right: 0.5vw;
    margin-left: 0.5vw;

}

.cart__table_delete_btn{
    background-color: #da8155;
    font-weight: lighter;
    font-size: 1.0vw;
    color: rgb(248, 245, 245);
    border: 1px solid #de2020;
    border-radius: 4px;
}

.cart__item_btn_group {
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.cart__item_btn {
    background-color: #586fd2;
    font-size: 1.2vw;
    color: rgb(248, 245, 245);
    border: 1px solid #007bff;
    border-radius: 4px;
    height: 6vh;
    width: 20vw;
    margin: 0 5px;
}

.cart__table_total_amount{
    font-size: 1.5vw;
    height: 7vh;
    text-align: center;
    font-weight: bold;
}