/*!* WarrantyPage.css *!*/

.warranty-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 70vh;
    margin-bottom: 20px;
}

.warranty-section {
    width: 100%;
    margin-bottom: 20px;
    text-align: left;
}

.warranty-image {
    width: 100%;
    height: 50vh;
    margin-top: 20px;
    margin-left: 20px;
}

.warranty-page h1 {
    text-align: start;
}

.warranty-page p {
    margin-left: 30px;
    text-align: left;
    margin-top: 20px;
    font-size: 22px;
}

h1 {
    text-align: center;
    margin-top: 30px;
    font-size: 50px;
}