.carousel__container {
    margin: 45px auto;
    max-width: 1500px;
}

.carousel {
    margin: 0 auto;
    width: 75%;
    position: relative;
    max-width: 100%;
    overflow: hidden;
}

.carousel__btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 1.5rem;
    border: none;
    padding: 1rem;
    cursor: pointer;
    z-index: 1;
    transition: background-color 0.3s;
}

.carousel__btn:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.carousel__btn--prev {
    left: 0;
}

.carousel__btn--next {
    right: 0;
}

.carousel__img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 42px;
    transition: transform 0.3s ease-in-out;
}

