.order__table_container {
    display: flex;
    justify-content: center;
    width: 45%;
    margin: 5vh 30% 3vh 30%;

}

.order__total_amount {
    display: flex;
    justify-content: center;
    margin-bottom: 20vh;
}

.order__table_row th {
    font-size: 1.5vw;
    height: 7vh;
    align-items: center;
    margin: 0px;
    padding: 0vh 5vh 0vh 0vh;
}

.order__table_row td {
    align-items: center;
    text-align: center;
}



.order__form_input {
    width: 55%;
    height: 55%;
    margin: 0 auto;
    border: 2px solid #1860e7;
    border-radius: 10px;
    padding: 3vh;
}

.order__form_input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1vh;
    margin-bottom: 5vh;
    height: 4vh;
}

.order__form_input label {
    margin-right: 5vh;
}

.order__form_input div {
    display: flex;
    align-items: normal;
    margin-top: 0.5vh;
    margin-bottom: 0vh;
    gap: 1.5vh;
}

/*.order__form_submit_container {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    margin-top: 2vh;*/
/*}*/

.order__form_submit_container{
    display: flex;
    justify-content: center;
    margin-top: 2vh;
    padding-top: 2vh;
    margin-right: 0vh;
    width: 30vw ;
    height: 7vh;
}


.order__form_submit{
    background-color: #3451e1;
    color: #fff;
    border: 1px solid #112370;
    border-radius: 1vw;
    cursor: pointer;
    width: 15vw ;
    height: 5vh;
    margin-right: 0vh;
}